<template>
    <right-sidebar display @close="$emit('close')" title="Beneficiary list">
        <template #body>
            <div class="payment-link-container-body">
                <div class="payment-link-container-body">
                <EmptyState v-if="!beneficiaryList.length" description="No Data available"/>
                    <div class="payment-details mt-2 cursor-pointer grid grid-cols-2 gap-2 items-center" v-for="beneficiary in beneficiaryList" :key="beneficiary.id">
                        <div>
                            <p class="text-sm text-dark truncate"> {{ beneficiary.accountName }}</p>
                            <p class="text-sm text-grey"> {{ beneficiary.accountNumber}}</p>
                            <p class="text-sm text-grey truncate"> {{ beneficiary.bankName}}</p>
                        </div>
                        <div>
                            <p class="text-sm text-dark"> {{ beneficiary.routingNumber ? beneficiary.routingNumber : beneficiary.swiftCode }}</p>
                            <p class="text-sm text-grey whitespace-normal"> {{ beneficiary.email }}</p>
                        </div>
                        <SecondaryButton text="Edit" p="p-1" width="w-12"/>
                        <Pagination v-if="showPagination"  :meta="pageMeta" :handleMore="handleMore" :loading="paginateLoading" @nextPage="handlePagination" />
                    </div>
                </div>
            </div>
        </template>
    </right-sidebar>
</template>
<script>
import { mapActions } from 'vuex'
import {RightSidebar} from '@/UI/Modals'
import {ACCOUNT_BENEFICIARY_LIST} from '@/utils/api/wire';
import {SecondaryButton} from '@/UI/Button'
import Pagination from '@/UI/Pagination'
export default {
    components:{
        RightSidebar,
        EmptyState:() => import('@/UI/EmptyState'),
        SecondaryButton,
        Pagination
    },
    data:() => ({
        params:{
            searchQuery:'',
            beneficiaryStatus:'',
            beneficiaryStatus:'',
            page: 0,
            pageSize: 20,
        },
        beneficiaryList:[],
        pageMeta: {},
        paginateLoading: false,
        showPagination: false
    }),
    methods:{
        ...mapActions('loading', ['setLoading']),
        getBeneficiaryList(type){
            type === "paginate" ? (this.paginateLoading = true) :  this.setLoading({
                display: true,
                description:'Getting beneficiary list'
            })
            ACCOUNT_BENEFICIARY_LIST(this.$route.params.id, this.params)
                .then(({data}) => {
                    console.log(data);
                    this.beneficiaryList = data.data;
                    this.pageMeta = res.data.meta || {}
                    type === "paginate" ? (this.paginateLoading = false) :  this.setLoading(false)
                }).catch(() => {
                    type === "paginate" ? (this.paginateLoading = false) : this.setLoading(false)
                })

        },
        handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            this.getBeneficiaryList("paginate");
        },
    },
    mounted(){
        this.getBeneficiaryList()
    }
}
</script>
<style scoped>
.payment-link-container-body {
    padding: 16px 24px;
}
.payment-details {
    padding-bottom: 8px;
    border-bottom: 1px solid #E3E8EF;
  }
</style>